import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import activityRequestDayParts from './modules/ActivityRequestDayPartStore'
import activityRequestStatus from './modules/ActivityRequestStatusStore'
import auth from './modules/auth'
import capabilityStore from './modules/CapabilityStore'
import clientVersion from './modules/ClientVersionStore'
import clockInConfirmedTypes from './modules/ClockInConfirmedTypesStore'
import clockIns from './modules/clockIns'
import date from './modules/date'
import dpiStatus from './modules/DpiStatusStore'
import draggables from './modules/draggables'
import employees from './modules/employees'
import employeeStatus from './modules/EmployeeStatusStore'
import preferences from './modules/PreferencesStore'
import suppliers from './modules/suppliers'
import vehicleRequestStatus from './modules/VehicleRequestStatusStore'
import vehicleStatus from './modules/VehicleStatusStore'
import vehicles from './modules/vehicles'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    activityRequestDayParts,
    activityRequestStatus,
    auth,
    capabilityStore,
    clientVersion,
    clockInConfirmedTypes,
    clockIns,
    date,
    dpiStatus,
    draggables,
    employees,
    employeeStatus,
    preferences,
    suppliers,
    vehicleRequestStatus,
    vehicleStatus,
    vehicles
  },

  plugins: [
    createPersistedState({
      key: 'vuex-admin',
      paths: [
        'activityRequestDayParts',
        'activityRequestStatus',
        'auth',
        'capabilityStore',
        'clientVersion',
        'clockInConfirmedTypes',
        'dpiStatus',
        'employeeStatus',
        'preferences',
        'vehicleRequestStatus',
        'vehicleStatus'
      ]
    })
  ]
})
