import { getVehicleStatuses } from '@/utils/api/config'
import translationsUtil from '@/utils/common/translationsUtil'

export default {
  state: {
    vehicleStatuses: {}
  },

  getters: {
    vehicleStatuses(state) {
      return state.vehicleStatuses
    }
  },

  actions: {
    initVehicleStatuses({ commit }) {
      commit('vehicleStatuses/update', getVehicleStatuses())
    }
  },

  mutations: {
    'vehicleStatuses/update'(state, payload) {
      // update vehicleStatuses
      state.vehicleStatuses = payload.map(vs => ({
        value: vs,
        text: translationsUtil('vehicleStatus_' + vs)
      }))
    }
  }
}
